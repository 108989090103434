import { useEffect } from 'react'
import { waitUser, WaitUserPromiseArgType } from '../utils/firebase.client'
import { useSubmit } from '@remix-run/react'
import { ActionFunctionArgs, json, LoaderFunctionArgs } from '@remix-run/node'
import { authenticator } from '../services/auth.server'
import { Spinner } from 'flowbite-react'

export async function loader({ request }: LoaderFunctionArgs) {
  await authenticator.isAuthenticated(request, { successRedirect: '/' })
  return json({})
}

export async function action({ request }: ActionFunctionArgs) {
  await authenticator.authenticate('imako-authentication', request, {
    successRedirect: '/',
  })
  return json({})
}

export default function Login() {
  const submit = useSubmit()

  let isWaitUser = false
  useEffect(() => {
    if (isWaitUser) return

    waitUser().then((value) => {
      const { token } = value as WaitUserPromiseArgType
      setTimeout(() => {
        submit({ token }, { method: 'post' })
      }, 200)
    })
    return () => {
      isWaitUser = true
    }
  }, [])

  return (
    <div
      className={`bg-gray-400/50 
                absolute
                top-50
                left-0
                w-full
                h-full
                z-10
                overflow-y-visible
                content-center
                text-center`}
    >
      <div className="flex justify-center items-center gap-10">
        <div className="text-xl">セットアップしています...</div>
        <Spinner aria-label="Loading" size="xl" className="z-20" color="gray" />
      </div>
    </div>
  )
}
